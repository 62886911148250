(function() {
    const popup = $('.coockies__popup');
    const button = $('.coockies__popup button');
    let aceito = localStorage.getItem('coockies');

    if(aceito) {
        popup.remove()
    }else {
        popup.removeClass('d-none')
    }

    button.click(function() {
        localStorage.setItem('coockies', 'true');
        popup.remove()
    })

})();